var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stack"},[_c('div',{staticClass:"stack-info"},[_c('div',{staticClass:"stack-info__content"},[_c('page-title',{staticClass:"stack-info__title",attrs:{"hint":true}},[_vm._v(" "+_vm._s(_vm.$t('title.server'))+" ")]),_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('title.hint'),
          placement: 'bottom-start',
          container: false,
        }),expression:"{\n          content: $t('title.hint'),\n          placement: 'bottom-start',\n          container: false,\n        }"}],staticClass:"stack-info__title-hint",attrs:{"icon":"help","size":"medium","color":"dim","tabindex":"-1"}}),_c('div',{staticClass:"stack-mode"}),_c('div',{staticClass:"stack-info__create",class:{ visible: false }},[_c('base-button',{staticClass:"stack-info__create-btn",attrs:{"icon":_vm.isIcon,"tooltip":{
            content: _vm.text,
            autoHide: false,
            placement: 'auto',
            container: false,
            trigger: 'click hover',
          },"color":_vm.isColor,"disabled":_vm.isRequest},on:{"click":function($event){!_vm.checkQuotas ? _vm.newDisk() : ''}}},[_vm._v(_vm._s(_vm.$t('newDisk')))])],1)],1)]),(_vm.isRequest)?_c('base-loader',{staticClass:"open-ticket__loader"}):(!_vm.isRequest)?_c('page-block',[(_vm.list.length === 0)?_c('base-empty',{staticClass:"cloud-info__empty",attrs:{"title":"$t('newDisk')"},scopedSlots:_vm._u([{key:"link",fn:function(){return [_c('base-button',[_vm._v(" "+_vm._s(_vm.$t('newDisk'))+" ")])]},proxy:true}],null,false,4186397553)}):_vm._e(),_c('tariffs-table-disk',{attrs:{"dataset":_vm.disks}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }